.template-home {

  &__hero {
    .hero {
      background: $dark-blue;
    }
  }

  &__content-testimony {
    .stats {
      //padding: 15px 0 40px;
      overflow: hidden;
      border-bottom: solid 1px $grey;

      display: flex;
      justify-content: space-between;
      align-items: stretch;
      align-content: stretch;
      gap: 30px;

      padding: 40px 0;


      &:last-of-type {
        border-bottom: none;
        margin-bottom: 20px;
      }

      &__figure {
        text-align: center !important;
        min-width: 170px;

        h3 {
          font-size: 6em;
          line-height: 1em;
          color: $white !important;
          font-variant-numeric: lining-nums;
          margin: 0;
        }
      }

      &__text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h4 {
          color: $white;
          font-family: $paragraph-font-face;
          margin: 0;
        }

        p {
          font-weight: 600;
          font-size: 22px;
          margin: 0;

          &.small-text {
            font-size: 16px;
            font-weight: 300;
            margin-top: 10px;
          }
        }
      }


    }

    .introduction {
      h2 {
        color: $white !important;
      }
    }

    .introduction,
    .stats,
    .button {
      @media (min-width: 901px) {
        margin-left: 20px;
      }

      @media (min-width: 1201px) {
        margin-left: 100px;
      }
    }

    .container {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: 901px) {
        display: block;
      }

      .testimonial {
        order: 2;
        margin: 60px -20px 0;

        @media (min-width: 901px) {
          margin: 0;
        }
      }

      .content {
        padding: 40px 0;
        @media (min-width: 901px) {
          padding-bottom: 40px;
        }
      }
    }
  }
}