.announcement {
  background: rgba(mix($black, $dark-blue), 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  &__container {
    @extend .container;
    text-align: center;
    font-size: 0.8em;
    padding: 5px !important;

    &, a {
      color: $white;
    }
  }
}