.template-blog {
  &__hero {
    height: 850px !important;

    .hero-banner__header-title {
      height: 850px !important;
    }

    .page-title {
      position: relative;
      top: 35% !important;
      transform: translateY(-35%) !important;
      padding-bottom: 0 !important;


      @media (min-width: 901px) {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 60px) !important;
      }

      h1 {
        margin-top: 0;
        @media (min-width: 901px) {
          font-size: 100px;
        }
      }

      .main-title {
        @media (min-width: 901px) {
          width: calc(65% - 20px);
          min-width: 600px;
        }
      }
    }
  }

  &__header-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 360px;

    @media (min-width: 901px) {
      margin-top: 100px;
      gap: 20px;
    }

    > div {
      display: inline-block;
      width: 100%;

      .button {
        padding: 10px 15px;
      }

      select {
        width: 100%;
      }
    }

    label {
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    select {
      margin-top: 4px;
      font-family: $paragraph-font-face;
    }
  }

  &__list {
    position: relative;
    margin-top: -300px !important;
    z-index: 2;

    &-items {
      background: $light-grey;
      padding-bottom: 100px;
    }
  }

  &__content {

    .container {
      position: relative;
      overflow: unset;
      z-index: 2;
    }

    .button {
      margin: 0 auto;

      .expand-icon {
        padding: 5px;
      }
    }
  }

  &__details {
    .categories {
      padding-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      text-align: left;
      gap: 10px 5px;

      .category {
        @extend .button;
        font-size: 0.7em !important;
        padding: 5px 12px !important;
        text-transform: uppercase;
        text-decoration: none;
        margin: 0 !important;
        letter-spacing: 1px;
        font-weight: 700;

        &:before {
          display: none;
        }

        &:hover {
          background: $pale-red;
        }

        &--lilac {
          background: $pale-lilac !important;
        }

        &--orange {
          background: $pale-orange !important;
        }
      }
    }
  }

  &__card {
    background: $white !important;
    transition: all 0.5s ease-out;

    &:not(:first-child) { // remove after slider function added
      display: block !important;
    }
  }

  .button {
    margin: 0 auto;
  }
}

.template-blog-article {
  &__hero {
    height: auto !important;

    .hero {
      padding: 150px 0 50px !important;
      background: $dark-blue !important;

      &::before {
        content: unset !important;
      }

      &__picture {
        height: 700px !important;
        overflow: hidden;

        img {
          left: 0 !important;
        }
      }
    }

    .hero-banner {
      padding: 40px 0;

      &__header-title {
        color: $white;
        position: relative;
        text-align: center;
        padding-bottom: 60px;

        .page-title {
          position: relative;
          padding-bottom: 0 !important;
          text-align: center;
          top: unset;
          transform: unset;
          width: 100%;

          @media (min-width: 901px) {
            padding: 0 0 60px;
          }

          .date {
            margin-bottom: 20px;
            color: $pale-blue;
            font-size: 18px;
          }

          p {
            font-size: 24px;
          }

          h1 {
            font-size: 34px;
            margin-bottom: 40px;
            margin-top: 20px;
            padding: 0 20px;

            @media (min-width: 901px) {
              font-size: 60px;
              line-height: 1.2;
              padding: 0;
            }
          }

          .categories {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 8px;

            .category {
              @extend .button;
              font-size: 12px !important;
              padding: 5px 20px !important;
              text-transform: uppercase;
              margin: 0;
              letter-spacing: 1px;
              font-weight: 700;
              color: $blue;

              @media (min-width: 969px) {
                font-size: 14px !important;
              }

              &:hover {
                background: $pale-red;
              }

              &--lilac {
                background: $pale-lilac !important;
              }

              &--orange {
                background: $pale-orange !important;
              }
            }
          }
        }
      }
    }
  }

  &__content {
    background: $dark-blue !important;

    p {
      margin-bottom: 10px;
    }
  }
}