.template-about {
  &__main-items {
    background: $blue;
  }

  &__hero {
    .hero {
      background: $dark-blue;
    }

    .hero-banner__title {
      .page-title {
        padding-bottom: 80px;
      }
    }
  }

  &__main-content {
    position: relative;
    color: $white;

    &::before {
      @media (min-width: 901px) {
        content: '';
        background: $blue;
        position: absolute;
        top: 28px;
        width: 30%;
        z-index: 2;
        height: 300px;
        left: 0;
        right: 0;
      }
    }

    &-container {
      z-index: 2;
      position: relative;
      margin-top: -29px !important;
      padding: 0 !important;

      @media (min-width: 901px) {
        margin-top: -160px !important;
        padding: 30px 20px 0;
      }
    }

    .testimonial {
      position: relative;

      @media (min-width: 901px) {
        @include grid-column(5);
      }

      .testimony {
        background: $white;
        padding: 50px 30px;
        position: relative;
        text-align: center;

        @media (min-width: 901px) {
          padding: 50px 60px;
        }

        .curly-quote {
          color: $pale-blue;

          &--lilac {
            color: $pale-lilac;
          }
        }

        .quote {
          position: relative;
          color: $grey;
          font-family: $heading-font-face;
          font-size: 35px;
          line-height: 1.1;
          margin-bottom: 40px;
          padding-bottom: 40px;

          @media (min-width: 1201px) {
            font-size: 50px;
          }

          &::after {
            content: "";
            position: absolute;
            border: solid 1px #C7E8F9;
            width: 70px;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
          }

          &--lilac {
            &::after {
              border: solid 1px $pale-lilac;
            }
          }
        }

        .date {
          color: $grey;
        }
      }

      &-image {
        @media (min-width: 901px) {
          height: 646px;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      &-icon {
        position: absolute;
        top: -25px;
        width: 100%;
        margin: 0 auto;
        left: 0;
        z-index: 1;
      }

      .name {
        color: $blue;
      }
    }

    .secondary-container {
      padding: 0;

      @media (min-width: 901px) {
        padding: 0 20px;
      }
    }

    .content {
      &-block {
        overflow: hidden;
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @media (min-width: 901px) {
          padding: 30px 0 60px;
        }

        .content-image {
          order: 1;
          width: 100%;

          @media (min-width: 901px) {
            width: calc(50% - 40px);
            order: 2;
          }
        }

        .text-content {
          order: 2;
          width: 100%;
          padding: 0 20px;

          @media (min-width: 901px) {
            width: calc(50% - 80px);
            padding: 0;
            order: 1;
          }

          p {
            margin-bottom: 20px;

            @media (min-width: 901px) {
              max-width: 601px;
            }
          }

          .title-blue {
            font-size: 24px;
            font-weight: 700;
            font-family: $paragraph-font-face;

            &, span {
              color: $pale-blue !important;
            }
          }

          &--top {
            margin-top: 40px;

            @media (min-width: 901px) {
              padding: 0 20px;
              margin-top: 150px;
              margin-left: 80px;
            }
          }

          &--left {
            padding-top: 40px;

            @media (min-width: 901px) {
              margin-right: 80px;
              padding-top: 0;
            }
          }

        }

        &__cards {
          display: flex;
          flex-wrap: wrap;
          padding: 40px 0;
        }

        &__card-items {
          padding: 0 20px;
        }
      }

      &-image {
        @media (min-width: 901px) {
          @include grid-column(6);
          height: 700px;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      &-card {
        background: $dark-blue;
        margin: 0 20px 20px;

        @media (min-width: 901px) {
          width: calc(50% - 20px);
          margin: 10px;
        }

        &__image {
          position: relative;
          overflow: hidden;
          height: 360px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        &__title {
          font-size: 24px;
          font-weight: 800;
          color: $pale-blue;
          margin-bottom: 20px;
          line-height: 1.4 !important;
        }

        &__details {
          padding: 30px 20px 50px;

          @media (min-width: 901px) {
            padding: 50px 60px 60px;
          }
        }
      }
    }
  }

  &__banner-left-overlay {
    max-height: 940px !important;
  }
}