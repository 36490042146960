.template-contact {
    &__hero {
        .page-title {
            top: 20%;
            position: absolute;
            z-index: 2;
            transform: translateY(-20%);
            margin-left: 0;

            @media screen  and (min-width: 900px) {
                top: 28% !important;
                transform: translateY(-28%) !important;
            }
            
            h1 {
                @media screen  and (min-width: 900px) {
                    margin: 10px 0 40px !important;
                }
            }

            .button {
                display: block;
                @media screen  and (min-width: 900px) {
                    display: none;
                }
            }
        }

        .hero {
            &__items {
                background: $blue;
                padding: 40px 0 0;
                color: $white;
                
                @media screen  and (min-width: 900px) {
                    position: absolute;
                    top: 75%;
                    transform: translate(-50%, -75%);
                    z-index: 10;
                    left: 50%;
                    width: 100%;
                    background: unset;
                    padding: 0;
                }

                .message {
                    a {
                        color: $white;
                    }
                }
            }
        }

        &.hero-banner--secondary,
        .hero__picture {
            @media screen  and (min-width: 900px) {
                height: 940px !important;
            }
        }
    }
}

.contact-address {
    background: $blue;
    color: $white;
    padding: 40px 0;

    @media screen  and (min-width: 900px) {
        padding: 80px 0 40px;
    }

    h2 {
        color: $white;
        &::after {
            margin: 0;
        }
    }

    .map {
        @media (min-width: 901px) {
            @include grid-column(7);
            margin-left: 0;
        }

        img {
            width:100%;
            margin-right: 20px;
        }
    }

    .address {
        @media (min-width: 901px) {
            @include grid-column(5);
        }

        &__inner {
            padding-top: 40px;

            @media (min-width: 901px) {
                margin-left: 80px;
            }

            .title--pale-blue {
                font-size: 20px;
                font-weight: 700;
            }
        }
    }
}