header {
  position: fixed;
  z-index: 9999;
  width: 100%;
  top: 0;
  left: 0;
  height: 126px;
  background: $top-gradient;

  @media (max-width: 1200px) {
    height: 110px;
  }

  @media (max-width: 800px) {
    height: 90px;
  }

  &::before {
    content: '';
    position: absolute;
    height: 80px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: $top-gradient;

    @media (min-width: 901px) {
      content: unset;
    }
  }

  .heading-links {
    position: absolute;
    z-index: 100;
    width: 100%;

    &.active {
      background: rgba($dark-blue, 0.9);

      .header-items {
        &__link {
          &.has-sub-menu {
            &:hover {
              background: $light-blue;
            }
          }
        }
      }
    }

    .inner-header {
      z-index: 10;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .logo {
        &__left {
          margin-left: 0;
          margin-top: 40px;

          @media (min-width: 1201px) {
            margin-top: 0;
          }

          .logo-image {
            width: 100px;

            @media (min-width: 769px) {
              width: 150px;
            }
          }
        }
      }
    }

    .header-items {
      &__menu-container {
        display: none;
        @media (min-width: 1201px) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      &__links {
        list-style: none;
        padding-inline-start: 0;
        text-align: right;
        margin: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-end;
      }

      &__link {
        display: inline;
        padding: 55px 25px;
        transition: all .2s ease;

        .sub-service {
          border-left: solid 3px transparent;
        }

        .sub-service:hover, .sub-service.current {
          border-left: solid 3px $pale-blue;

          a {
            text-decoration: none !important;
          }
        }

        &--lilac .sub-service:hover, &--lilac .sub-service.current {
          border-left: solid 3px $pale-lilac;
        }

        &--red .sub-service:hover, &--red .sub-service.current {
          border-left: solid 3px $pale-red;
        }

        &--orange .sub-service:hover, &--orange .sub-service.current {
          border-left: solid 3px $pale-orange;
        }

        @media (max-width: 1281px) {
          padding: 55px 20px;
        }

        @media (max-width: 1400px) {
          padding: 55px 15px;
        }

        img {
          transition: all 0.25s ease-out;
        }

        &.active {
          .sub-menu {
            @media (min-width: 1201px) {
              display: block;
              position: absolute;
              padding: 30px 0;
              z-index: 1000;
              top: 130px;
              left: 0;
              right: 0;
              height: 100vh;
              text-align: left;
              background: rgba($dark-blue, 0.9);
              border-top: solid 1px rgba(243, 242, 248, 0.2);

              .sub-menu__block {
                @media (min-width: 1201px) {
                  /* @include grid-column(3);
                   margin-bottom: 20px;*/
                }

                li {
                  &.main-service {
                    padding: 0 0 15px;

                    a {
                      font-weight: bold;
                    }

                    &--individual {
                      margin-top: 40px;
                    }
                  }

                  &.sub-service {
                    margin-bottom: 20px;
                    padding-left: 10px;

                    a {
                      font-size: 16px;
                    }
                  }

                  &:hover {
                    a {
                      text-decoration: underline;
                    }
                  }
                }
              }
            }

            &__container {
              max-height: calc(100vh - 200px); /* maximum height of the container */
              overflow-y: auto; /* enables scrolling if content exceeds max-height */
            }

            &__inner {
              max-height: 800px; /* maximum height of the container */
              column-count: 4; /* create 4 columns */
              column-gap: 20px; /* space between columns */
            }

            &__block {
              padding: 20px; /* padding inside blocks */
              box-sizing: border-box; /* include padding and border in the element's size */
              display: inline-block; /* allows the block to flow naturally within the column */
              width: 100%; /* ensure block fills the column width */
              margin-bottom: 10px; /* space between blocks */
              break-inside: avoid; /* avoid breaks within blocks */
            }
          }

          img {
            transform: rotate(180deg);
          }
        }

        a {
          color: $white;
          text-decoration: none;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 8px;
        }

        .sub-menu {
          display: none;
        }
      }

      &__buttons {
        border-left: solid 1px $light-grey;
        margin-left: 0;
        padding: 4px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        gap: 22px;

        .contact-button {
          margin-left: 25px;

          @media (max-width: 1281px) {
            margin-left: 20px;
          }

          @media (max-width: 1400px) {
            margin-left: 15px;
          }
        }

        .search {
          margin-top: 5px;

          img {
            height: 15px;
            background: $light-grey;
            border-radius: 100%;
            padding: 13px;
            cursor: pointer;
          }
        }
      }
    }

    .mobile-menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      margin-top: 30px;

      @media (min-width: 1201px) {
        display: none;
      }

      .contact-button {
        margin-right: 15px;

        @media (max-width: 500px) {
          .button {
            padding: 5px 4px 5px 14px;

            a {
              font-size: 12px;
              font-weight: 600;

              .arrow-right {
                height: 10px;
              }
            }
          }
        }
      }

      .burger-menu {
        width: 30px;
        height: 16px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;

        span {
          display: block;
          position: absolute;
          height: 1px;
          width: 100%;
          background: $white;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: .25s ease-in-out;

          &:nth-child(1) {
            top: 0px;
            transform-origin: left center;
          }

          &:nth-child(2) {
            top: 8px;
            transform-origin: left center;
          }

          &:nth-child(3) {
            top: 16px;
            transform-origin: left center;
          }
        }

        &.open {
          span {
            &:nth-child(1) {
              transform: rotate(45deg);
              top: -3px;
              left: 8px;
            }

            &:nth-child(2) {
              width: 0%;
              opacity: 0;
            }

            &:nth-child(3) {
              transform: rotate(-45deg);
              top: 18px;
              left: 8px;
            }
          }
        }
      }

      &__scroll {
        height: calc(100vh - 188px);
        overflow-x: hidden;
        overflow-y: auto;
      }

      &__search {
        padding: 20px 20px 10px 20px;

        input {
          font-family: $paragraph-font-face;
          outline: none;
          border: none;
          border-radius: 50px;
          width: 100%;
          font-size: 18px;
          padding: 10px 20px 10px 42px;
          box-sizing: border-box;
          color: $blue;
          line-height: 1em;
          transition: all 0.25s ease;

          background-color: $white;
          background-image: url('../images/nav-search.svg');
          background-size: 22px;
          background-repeat: no-repeat;
          background-position: 13px 10px;

          &:focus {
            padding-left: 20px;
            padding-right: 42px;
            background-image: url('../images/return-key.svg');
            background-position: 96.5% 15px;
          }
        }
      }

      &__item {
        border-bottom: solid 1px rgba(219, 222, 232, 0.4);
      }

      &__items {
        height: 0;
        overflow: hidden;
        background: rgba($dark-blue, 0.9);
        padding: 0;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 0;
        transition: all 0.5s ease-out;
        opacity: 0;

        &.open {
          opacity: 1;
          height: 100vh;
          overflow: visible;
          padding: 90px 0 50px;
        }

        ul {
          margin: 0;

          li {
            padding: 15px 20px;
            min-height: 40px;
          }
        }

        a, span {
          color: $white;

          &, &:hover {
            text-decoration: none;
          }
        }

        span {
          cursor: pointer;
        }

        .top-level-menu {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .expand-btn {
            margin: 0 !important;
          }

          a {
            font-size: 18px;
            font-weight: 700;
          }
        }

        .sub-service {
          a {
            font-size: 16px;
          }
        }

        .main-service {
          padding-right: 0;
        }

        .top-level-link {
          font-size: 18px;
          font-weight: 700;
        }

        .sub-service {
          padding-left: 30px;
        }

        .sub-menu {
          margin: 10px 0;

          &-sub-menu {
            padding-left: 30px;
          }
        }

        .sub-menu,
        .sub-sub-menu {
          display: none;
        }
      }

      &__contact-socials {
        padding-top: 40px;
        padding-bottom: 40px;

        p,
        a {
          font-size: 16px;
          color: $white;
          display: block;
          margin-bottom: 10px;
        }

        .socials {
          li {
            padding: 15px 20px 0 0;

            a {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.search-overlay {
  background: rgba($dark-blue, 0.9);
  z-index: 9999;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: none;

  &__container {
    @extend .container;
    display: block;
    padding: 145px 20px 45px 20px !important;
  }

  label {
    display: block;
    color: $grey;
    font-family: $heading-font-face;
    font-size: 1.2em;
    margin-bottom: 15px;

    img {
      width: 20px;
      display: inline-block;
      margin-left: 2px;
      position: relative;
      top: -1px;
    }
  }

  input {
    background: transparent;
    color: $white;
    border: 0;
    font-family: $paragraph-font-face;
    font-size: 4em;
    font-weight: 100;

    &:active, &:focus {
      border: 0;
      outline: none;
    }
  }

  @media (max-width: 900px) {
    &__container {
      padding-top: 110px !important;
    }

    input {
      font-size: 3em;
    }
  }

  @media (max-width: 500px) {
    label {
      margin-bottom: 5px;
      font-size: 1em;
    }

    input {
      font-size: 2em;
    }
  }
}