html {
  font-size: 10px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;

  @media (min-width: 2301px) {
    font-size: 10px;
  }
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-family: $paragraph-font-face;
  position: relative;
  font-size: 18px;
  color: $blue;
  background: $white;
}

.container {
  // width: 100%;  /* Breaks and pushes contents out of the window...*/
  max-width: 1520px;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 20px;
  width: auto;
}

.secondary-container {
  max-width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 20px;
  width: auto;
}

.button {
  background: $pale-blue;
  border: none;
  padding: 11px 16px 11px 25px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  font-family: $paragraph-font-face;
  position: relative;
  overflow: hidden;

  &.hidden {
    display: none;
  }

  a, & {
    position: relative;
    z-index: 200;
    transition: all 0.5s ease;
  }

  img {
    transition: all 0.5s ease;
  }

  &:before {
    transition: all 0.8s ease-out;
    content: '';
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 100%;
    position: absolute;
    right: 15px;
    z-index: 1;
    transform: translate(-50%, -50%);
    left: var(--cursorX);
    top: var(--cursorY);
  }

  &:hover:before {
    background: darken($pale-red, 7%);
    width: 400px;
    height: 400px;
    aspect-ratio: 1 / 1;
  }

  &:hover {
    &, a, span {
      color: $white;
    }

    img {
      transform: scale(0.9);
    }
  }

  @media (min-width: 901px) {
    font-size: 18px;
  }

  a, span {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    color: $blue;
    z-index: 2;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 0 !important;
  }

  .arrow-right,
  .expand-icon {
    height: 12px;
    background: $white;
    padding: 4px 7px;
    border-radius: 100%;
    margin-left: 10px;
    z-index: 2;
  }

  &--lilac {
    background: $pale-lilac;
  }

  &--orange {
    background: $pale-orange;
  }
}

.chevron-down {
  width: 10px;
}

a {
  color: $blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  margin: 0 0 20px 0;
  line-height: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-face;
  color: $dark-blue;
  line-height: 1;
}

h1 {
  font-size: 45px;

  @media (min-width: 769px) {
    font-size: 60px;
  }

  @media (min-width: 901px) {
    font-size: 80px;
  }

  @media (min-width: 1201px) {
    font-size: 100px;
  }
}

h2 {
  padding-bottom: 30px;
  position: relative;
  font-size: 34px;
  line-height: 1em;

  @media (min-width: 769px) {
    font-size: 35px;
  }

  @media (min-width: 901px) {
    font-size: 50px;
  }

  &::after {
    content: '';
    position: absolute;
    border: solid 1px $pale-blue;
    width: 70px;
    left: 0;
    bottom: 0;
    right: 0;

    @media (min-width: 901px) {
      margin: 0 auto;
    }
  }
}

ul {
  padding-inline-start: 0;
  text-decoration: none;

  li {
    list-style: none;
  }
}

.testimonial-icon {
  height: 50px;
  width: 50px;
}

.title--pale-blue {
  color: $pale-blue;
}

.fixed-review-solicitors {
  display: none;

  @media (min-width: 901px) {
    display: block;
    position: absolute;
    z-index: 100;
    top: 800px;
    right: 0;

    img {
      position: absolute;
      z-index: 1000;
      bottom: 100%;
      transform: rotateZ(-90deg);
      -webkit-transform: rotateZ(-90deg);
      transform-origin: 0 100%;
      background: $white;
      padding: 8px 20px;
      width: 200px;
      white-space: nowrap;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }
  }
}

.expand-btn {
  display: block;
  padding: 0;
  border-radius: 100%;
  border: solid 2px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
  cursor: pointer;
  aspect-ratio: 1 / 1;
  width: 33px;
  height: auto;
  text-align: center;

  @media (min-width: 1201px) {
    display: none;
  }

  &--lilac {
    color: $pale-lilac;

    &.expanded {
      background: $pale-lilac;
      color: $blue;
    }
  }

  &--blue {
    color: $pale-blue;

    &.expanded {
      background: $pale-blue;
      color: $blue;
    }
  }

  &--orange {
    color: $pale-orange;

    &.expanded {
      background: $pale-orange;
      color: $blue;
    }
  }

  &--red {
    color: $pale-red;

    &.expanded {
      background: $pale-red;
      color: $blue;
    }
  }
}

.bold {
  font-weight: 700;
}


.socials {
  ul {
    margin: 0;

    li {
      display: inline-block;
      margin-left: 0;
      @media (min-width: 901px) {
        margin-left: 20px;
      }

      img {
        height: 30px;
        background: $pale-blue;
        border-radius: 100%;
        padding: 2px;
      }
    }
  }

  &--mobile {
    display: block;

    @media (min-width: 901px) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @media (min-width: 901px) {
      display: block;
    }
  }
}

.carousel-placeholder, .slick-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  span, li {
    border: solid 2px $pale-lilac;
    border-radius: 100%;
    height: 8px;
    width: 8px;
    margin: 0 5px;
    cursor: pointer;

    &.active, &.slick-active {
      background: $pale-lilac;
      height: 12px;
      width: 12px;
      border: none;
    }

    button {
      display: none;
    }
  }

  @media (min-width: 901px) {
    display: none;
  }

  &--grey {
    span, li {
      border: solid 2px $grey;

      &.active, &.slick-active {
        background: $grey;
      }
    }
  }
}

.js-animate-in {
  transition: all 0.5s ease-in;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

::-moz-selection {
  background: $pale-red;
  color: $blue;
}

::selection {
  background: $pale-red;
  color: $blue;
}