.template-terms {
  &__hero {
    &.hero-banner {
      height: 500px !important;

      .page-title {
        position: relative;
        width: 100% !important;
        margin-left: 0 !important;
        top: 55%;
        transform: translateY(-55%);
        float: none !important;
        left: 0;
        right: 0;

        @media (min-width: 901px) {
          top: 45%;
          transform: translateY(-45%);
        }

        h1 {
          margin-bottom: 40px;
          margin-top: 20px;
          font-size: 34px;

          @media (min-width: 901px) {
            font-size: 60px;
          }
        }
      }
    }

    .hero-banner {
      &__header-title {
        height: 300px !important;
      }
    }
  }

  &__main-content {

    h3 {
      text-transform: capitalize;
    }

    .main-content {
      border-bottom: 0 !important;
    }

    .highlight {
      margin-bottom: 40px !important;
    }

    .secondary-container {
      padding-top: 60px !important;

      h2 {
        padding-bottom: 0;
        margin: 30px 0 20px 0;

        &:after {
          content: none;
        }
      }

      h3 {
        margin: 30px 0 15px 0;
      }

      ul, li {
        list-style-type: square !important; /* Default bullet style: disc, circle, square, none */
      }

      ul {
        margin: 0 0 40px 0;
        padding-left: 40px; /* Left padding for indentation */
        line-height: 1.5em;

        li {
          margin: 0 0 20px 0;

          ul {
            margin: 20px 0 40px 0;
          }
        }
      }

      table {
        background: $white;
        padding: 20px;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 1em;
        line-height: 1.5em;

        td, th {
          text-align: left;
          vertical-align: top;
          padding: 40px 30px;
          border-bottom: 4px solid mix($light-blue, $light-grey, 15%);
        }

        th {
          background: $dark-blue;
          color: $white;
          padding: 20px 30px;
        }
      }

      p {
        margin-bottom: 20px;
      }

      > p:first-child {
        font-size: 1.25em;
      }
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px;

      @media (min-width: 901px) {
        font-size: 28px;
      }
    }
  }
}