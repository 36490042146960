.template-careers {
  &__careers-list {
    position: relative;
    z-index: 10;
    background: $blue;
    color: $white;
  }

  &__content-left {
    position: relative;
    z-index: 5;

    &::before {
      width: 60% !important;
    }

    .content-left__item {
      @media (min-width: 901px) {
        @include grid-column(7);
      }
    }
  }

  &__hero {
    @media (min-width: 901px) {
      height: 760px !important;
    }

    .page-title {
      position: absolute;
      top: 70%;
      transform: translateY(-70%);
      z-index: 2;
    }

    .hero__picture {
      height: 600px !important;

      @media (min-width: 901px) {
        height: 760px !important;
      }
    }
  }
}

.careers-list {
  &__information {
    margin-top: 0;
    @media (min-width: 1201px) {
      @include grid-column(7);
      margin-left: 0;
      margin-bottom: 40px;
    }

    h2 {
      line-height: 1.4;
      padding: 0 0 10px;
      color: $white;

      &::after {
        margin: 0;
      }
    }
  }

  &__links {
    position: relative;
    background: $blue;
    padding: 40px 0 0;
    color: $white;
    @extend %clearfix;
    min-height: 360px;

    @media (min-width: 901px) {
      padding: 0;
    }

    .main-links {
      @media (min-width: 901px) {
        @include grid-column(8);
        margin-left: 0;
      }

      ul {
        li {
          margin-bottom: 20px;

          @media (min-width: 901px) {
            margin-right: 40px;
          }

          .button {
            font-size: 18px;
            line-height: 1.2;
            padding: 8px 10px 8px 20px;
            color: $pale-blue;
            background: $dark-blue;
            justify-content: space-between;
            border-radius: 45px;
            text-decoration: none;

            &:before {
              display: none;
            }

            &:hover {
              background: darken($blue, 5%);
            }

            @media (min-width: 901px) {
              font-size: 28px;
              padding: 16px 20px 16px 40px;
            }

            .arrow-right {
              background: $pale-blue;
              padding: 8px 10px;
              height: 12px;
              margin-left: 40px;

              @media (min-width: 901px) {
                padding: 13px 16px;
                height: 14px;
              }
            }
          }
        }
      }
    }

    .message {
      margin-top: 40px;

      @media (min-width: 901px) {
        @include grid-column(4);
        margin-left: 40px;
        margin-top: 0;
      }

      a {
        color: $white;
      }

      .title--pale-blue {
        padding-bottom: 20px;
      }
    }

    .title--pale-blue {
      font-weight: bold;
      line-height: 1.4;
      font-size: 28px;
    }
  }
}