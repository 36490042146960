/** Import theme styles */
@import "../neat/neat";
@import "../node_modules/slick-carousel/slick/slick.css";
@import "../node_modules/select2/select2.css";
@import "settings/fonts";
@import "settings/colours";
@import "sections/home";
@import "sections/about";
@import "sections/contact";
@import "sections/blog";
@import "sections/business";
@import "sections/careers";
@import "sections/terms";
@import "sections/team";
@import "sections/general-text";
@import "components/header";
@import "components/footer";
@import "components/common";
@import "components/announcement";
@import "base/base";
@import "base/type";
@import "tools/custom";

.select2-container {
  background: transparent;
  display: block;
  color: $blue !important;
}

.select2-choice {
  background: $pale-blue !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  border: 0 !important;
  margin-top: 10px;
}

.select2-arrow {
  display: none !important;
}

.select2-drop {
  margin: 0 20px;
  width: 320px !important;
  background: $pale-blue !important;
}

.select2-results .select2-highlighted {
  background: $pale-red;
  color: $white;
}

.select2-results .select2-no-results, .select2-results .select2-searching, .select2-results .select2-ajax-error, .select2-results .select2-selection-limit {
  background: transparent !important;
  color: $blue;
}

.select2-search input {
  background-color: transparent !important;
  border-radius: 50px;
  border: 0;
  color: $blue;
}