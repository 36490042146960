.template-business {
  &__services {
    background: $blue;
    color: $white;

    h2 {
      text-align: center;
      color: $white;

      &::after {
        border: solid 1px $pale-lilac;
        margin: 0 auto;
      }
    }

    &-list {
      @media (min-width: 769px) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .service-card {
      margin: 0 0 30px;
      background: $dark-blue;
      position: relative;

      &__cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
      }

      @media (min-width: 769px) {
        width: calc(50% - 20px);
        margin: 0 10px 30px;
      }

      @media (min-width: 901px) {
        width: calc(33.33% - 20px);
      }

      &__image {
        position: relative;
        overflow: hidden;
        height: 200px;

        @media (min-width: 401px) {
          height: 280px;
        }

        &::before {
          content: '';
          height: 100%;
          width: 100%;
          position: absolute;
          bottom: -75px;
          right: 0;
          z-index: 1;
          background: $mobile-blue-gradient;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }

      &__title {
        font-size: 28px;
        font-weight: 800;
        color: $pale-lilac;
        margin-bottom: 20px;
      }

      &__details {
        padding: 0 20px 40px;
        text-align: center;

        @media (min-width: 901px) {
          padding: 0 50px 60px;
        }

        .button {
          margin: 50px auto 0;
        }

        p {
          line-height: 1em;
        }
      }
    }
  }

  &__hero {
    .hero-banner__header-title {
      background: $dark-blue;
    }

    .page-title {
      @media (min-width: 901px) {
        width: 600px !important;
      }
    }
  }
}

.template-business-subpage {
  &__hero {
    @media (min-width: 901px) {
      height: 680px !important;
    }

    .hero__picture {
      height: 580px !important;

      @media (min-width: 901px) {
        height: 680px !important;
      }
    }

    .hero-banner__header-title {
      height: 580px !important;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      @media (min-width: 901px) {
        height: 680px !important;
      }

      .page-title {
        top: 80%;
        transform: translateY(-80%);
        padding-bottom: 0;

        @media (min-width: 901px) {
          top: 50%;
          transform: translateY(-50%);
        }

        p {
          margin-bottom: 10px;
        }

        h1 {
          margin: 0 0 50px;
        }
      }
    }
  }
}

.template-business-childpage {
  &__hero {
    height: 300px !important;
    padding-top: 15px;

    @media (min-width: 901px) {
      height: 600px !important;
    }

    .hero-banner__header-title {
      height: 300px !important;

      @media (min-width: 901px) {
        height: 647px !important;
      }

      .page-title {
        position: absolute;
        top: 80%;
        transform: translateY(-80%);
        padding: 0;
        z-index: 3;

        @media (min-width: 901px) {
          top: 50%;
          transform: translateY(-50%);
        }

        .service-type {
          font-size: 14px;
          margin-bottom: 10px;

          @media (min-width: 901px) {
            font-size: 18px;
          }
        }
      }
    }
  }

  &__fixed-review-solicitors {
    top: 500px !important;
  }

  &__blog-insights {
    &::before {
      height: 434px !important;
    }
  }
}

.template-business-subpage,
.template-business-childpage {
  &__hero {
    .service-type {
      margin-bottom: 0;
      font-weight: bold;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &--lilac {
        a, & {
          color: $pale-lilac;
        }
      }

      &--orange {
        a, & {
          color: $pale-orange;
        }
      }

      &--blue {
        a, & {
          color: $pale-blue;
        }
      }

      &--red {
        a, & {
          color: $pale-red;
        }
      }

      a:hover {
        text-decoration: none;
        opacity: 0.8;
      }

      .chevron-right {
        height: 6px;
        transform: rotate(-90deg); // remove once the right chevron right icon is added
        margin: 10px;

        @media (min-width: 901px) {
          height: 10px;
        }
      }
    }

    .hero-banner__header-title {
      .page-title {
        position: absolute;
        z-index: 3;
      }
    }
  }
}