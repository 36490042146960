.general-text {
  background: $light-grey;

  &--dark {
    background: $blue;

    h1, h2, h3, h4, h5, p, &, a {
      color: $white;
    }
  }

  &__container {
    max-width: 1154px;
    margin: 0 auto;
    padding: 85px 20px;

    h1 {
      margin: 0 0 30px 0;
      padding: 0;

      &:after {
        display: none;
      }
    }

    p {
      margin: 0 0 30px 0;
    }

    h2 {
      margin: 0 0 25px 0;
      padding: 0;

      &:after {
        display: none;
      }
    }

    h3 {
      margin: 35px 0 10px 0;
      padding: 0;
      font-size: 1.75em;

      &:after {
        display: none;
      }
    }

    h4 {
      font-size: 1.35em;
      color: $dark-blue;
    }

    h5 {
      font-size: 1.15em;
      color: $light-blue;
    }

    ul, li {
      list-style-type: square !important; /* Default bullet style: disc, circle, square, none */
    }

    ul {
      margin: 0 0 40px 0;
      padding-left: 40px; /* Left padding for indentation */
      line-height: 1.5em;

      li {
        margin: 0 0 20px 0;

        ul {
          margin: 20px 0 40px 0;
        }
      }
    }

    table {
      background: $white;
      padding: 20px;
      border-collapse: collapse;
      border-spacing: 0;
      font-size: 1em;
      line-height: 1.5em;

      td, th {
        text-align: left;
        vertical-align: top;
        padding: 40px 30px;
        border-bottom: 4px solid mix($light-blue, $light-grey, 15%);
      }

      th {
        background: $dark-blue;
        color: $white;
        padding: 20px 30px;
      }
    }
  }
}