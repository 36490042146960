.contact {
  padding: 50px 20px !important;

  h2 {

    color: $dark-blue;

    @media (min-width: 901px) {
      text-align: center;
    }
  }
}

#template__footer {
  border-top: solid 1px $light-grey;
}

.footer {
  padding-bottom: 30px;

  &__links {
    padding: 50px 0 20px;

    @media (min-width: 901px) {
      border-bottom: solid 1px $light-grey;
      padding: 4rem 0;
    }

    &-logos {
      margin-bottom: 30px;

      @media (min-width: 901px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0;
      }
    }

    .top-level-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (min-width: 901px) {
        display: block;
      }
    }

    .top-level-link {
      font-size: 19px;
      font-weight: 700;
    }

    .footer-logo {
      img {
        width: 134px;
      }
    }

    .accreditations {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      justify-content: flex-start;
      gap: 20px;

      @media (min-width: 901px) {
        margin-top: 0;
        gap: 0;
      }

      span {
        margin-right: 20px;

        @media (min-width: 901px) {
          margin-left: 20px;
          margin-right: 0;
        }

        @media (min-width: 1201px) {
          margin-left: 40px;
        }

        img {
          max-height: 50px;

          @media (min-width: 401px) {
            max-height: 60px;
          }

          @media (min-width: 901px) {
            max-height: 60px;
          }
        }
      }
    }

    ul {
      li {
        margin-bottom: 20px;
        padding: 0 20px;

        @media (min-width: 901px) {
          padding: 0;
        }
      }

      &.sub-menu {
        margin-top: 20px;

        &:hover {
          a {
            text-decoration: none;
          }
        }

        a {
          border-bottom: dotted 1px $lighter-grey;
        }
      }
    }
  }

  &-menu {
    &__items {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 -20px;
    }

    &__item {
      width: 100%;
      @media (min-width: 901px) {
        width: calc(25% - 40px);
        margin: 20px;
      }

      &:first-child {
        border-top: solid 1px $light-grey;

        @media (min-width: 901px) {
          border: none;
        }
      }

      ul {
        border-bottom: solid 1px $light-grey;
        margin-bottom: 0;

        @media (min-width: 901px) {
          border-bottom: unset;
        }


        &.sub-menu {
          display: none;
          border: none;

          @media (min-width: 901px) {
            display: block;
          }
        }

        .expand-btn {
          display: none;

          @media (max-width: 900px) {
            display: block;
          }

          &--lilac {
            &.expanded {
              border: solid 2px $pale-lilac;
            }
          }

          &--blue {
            &.expanded {
              border: solid 2px $pale-blue;
            }
          }

          &--orange {
            &.expanded {
              border: solid 2px $pale-orange;
            }
          }

          &--red {
            &.expanded {
              border: solid 2px $pale-red;
            }
          }
        }
      }
    }
  }

  &__company-details {
    padding: 20px !important;

    @media (min-width: 901px) {
      padding: 4rem 20px !important;
    }

    .company-details {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .column {
        margin-bottom: 40px;
        width: 100%;

        @media (min-width: 901px) {
          @include grid-column(3);
          margin-bottom: 0;
        }

        &.contact-details {
          order: 1;

          @media (min-width: 901px) {
            order: unset;
          }

          p.bold {
            margin: 0 0 10px 0;
          }

          a {
            display: block;
            padding: 0;
            text-decoration: none;

            span {
              border-bottom: dotted 1px $lighter-grey;
            }
          }
        }

        &.address {
          order: 2;

          @media (min-width: 901px) {
            order: unset;
          }
        }

        &.authorised {
          order: 3;

          @media (min-width: 901px) {
            order: unset;
          }
        }

        &.sra-authorised-logo {
          order: 4;

          @media (min-width: 901px) {
            text-align: right;
            order: unset;
          }

          img {
            max-height: 80px;
          }
        }
      }
    }
  }

  &__legal-terms {
    @media (min-width: 769px) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &__terms-links {
    @media (min-width: 901px) {
      margin-left: 20px;
    }

    ul {
      @media (min-width: 901px) {
        margin-top: 135px;
        margin-bottom: 0;
      }

      li {
        display: inline-block;
        margin-right: 20px;

        a {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 1px;
        }
      }
    }
  }

  &__socials-delivered {
    @media (min-width: 901px) {
      text-align: right;
    }

    .delivered-details {
      margin-top: 40px;

      p {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 14px;
      }

      a {
        font-weight: 600;
      }
    }
  }
}

#rswidget_wgokd {
  display: block;
}

#rswidget_RVN4k {
  display: none;
}

@media (max-width: 1500px) {
  #rswidget_wgokd {
    display: none;
  }

  #rswidget_RVN4k {
    display: block;
    z-index: 9999;
  }
}
