/* Hero with content */
.hero-banner {
  position: relative;
  overflow: hidden;
  z-index: 1;

  @media (min-width: 901px) {
    height: 900px;
  }

  .hero {
    &::before {
      @media (min-width: 901px) {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        background: $desktop-blue-gradient;
      }
    }

    &__picture {
      position: relative;
      overflow: hidden;
      height: 300px;
      width: 100%;
      display: flex;

      @media (min-width: 901px) {
        height: 900px;
      }

      &::before {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: -115px;
        right: 0;
        z-index: 1;
        background: $mobile-blue-gradient;

        @media (min-width: 901px) {
          content: unset;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        object-position: top;

        @media (min-width: 901px) {
          left: 240px;
        }
      }
    }

    .button-links {
      display: flex;
      gap: 20px;

      .button {
        display: inline-block;
        margin-bottom: 20px;
      }

      @media (max-width: 660px) {
        gap: 0;
        flex-wrap: wrap;
      }
    }
  }

  &--secondary {
    .hero {
      &::before {
        content: none;
      }

      &__picture {
        height: 500px;

        @media (min-width: 901px) {
          height: 760px;
        }

        &::before {
          content: '';
          background: rgba(25, 35, 54, 0.8);
          top: 0;
          bottom: 0;
        }

        img {
          height: 100%;
          left: 0;
        }
      }

      &-banner__header-title {
        .page-title {
          width: calc(95% - 20px) !important;

          @media (min-width: 901px) {
            width: calc(60% - 20px) !important;
          }
        }
      }
    }
  }

  &--no-image {
    background: $dark-blue;
    overflow: unset;
    height: 680px;

    h1 {
      font-size: 40px;

      @media (min-width: 901px) {
        font-size: 80px;
      }

      @media (min-width: 1201px) {
        font-size: 100px;
      }
    }

    .hero-banner__header-title {
      position: relative;

      @media (min-width: 901px) {
        height: 920px;
      }

      .page-title {
        @media (min-width: 1201px) {
          /*display: flex;
          gap: 20px*/
        }
      }
    }
  }

  &__header-title {
    color: $white;

    .page-title {
      padding-bottom: 40px;
      width: 100%;

      @media (min-width: 901px) {
        padding-bottom: 80px;
        position: absolute;
        z-index: 1;
      }

      @media (min-width: 901px) {
        @include grid-column(8);
        top: 50%;
        transform: translateY(-50%);
      }

      @media (min-width: 1201px) {
        width: 650px;
      }

      p {
        font-size: 18px;
        margin-bottom: 40px;
        line-height: 1.5;

        @media (min-width: 901px) {
          font-size: 24px;
        }
      }

      h1 {
        color: $white !important;
        margin-bottom: 50px;
        margin-top: 0;
        line-height: 0.85em;
      }

      &--center {
        text-align: center !important;
        margin: 0 auto !important;
        position: relative;
        padding-top: 175px;

        h1 {
          margin-left: auto;
          margin-right: auto;
        }

        @media (min-width: 901px) {
          width: calc(100% - 60px);
        }
      }

      .button {
        p {
          margin-bottom: 0;
          font-size: 18px;
          line-height: 1;
        }
      }
    }
  }
}

/* Page contents */
.content-left-testimonial {
  position: relative;
  background: $blue;
  padding: 40px 0 0;

  @media (min-width: 901px) {
    padding: 40px 0 80px;
    background: $blue;
  }

  &::before {
    @media (min-width: 901px) {
      content: '';
      background: $blue;
      position: absolute;
      top: -90px;
      width: 30%;
      z-index: 2;
      height: 300px;
      left: 0;
      right: 0;
    }
  }

  .container {
    padding-top: 40px;
    margin-top: -80px;
    position: relative;
    z-index: 2;

    @media (min-width: 901px) {
      margin-top: -160px;
      padding: 0 20px;
      padding-top: 30px;
      background: transparent;
    }

    .testimonial {
      margin: 0 -20px;

      @media (min-width: 901px) {
        margin: 0;
      }
    }
  }

  .content {
    color: $white;

    @media (min-width: 901px) {
      @include grid-column(6);
      margin-top: 180px;
      float: right;
    }

    .introduction {
      border-bottom: solid 1px $grey;
      padding-bottom: 40px;

      h2 {
        &::after {
          margin: 0;
        }
      }
    }

    &--small {
      color: $white;
      padding-top: 40px;
      line-height: 1.5em;

      @media (min-width: 901px) {
        @include grid-column(6);
        margin-top: 100px;
        padding-left: 20px;
        position: relative;
      }

      p {
        @media (min-width: 901px) {
          padding: 0 50px;
          line-height: 2;
        }

        @media (min-width: 1201px) {
          padding: 0 90px 0;
          line-height: 2;
        }
      }
    }
  }

  .testimonial {
    @media (min-width: 901px) {
      @include grid-column(6);
    }

    &-image {
      overflow: hidden;
      position: relative;
      height: 250px;
    }

    .testimony {
      background: $white;
      padding: 50px 40px;
      position: relative;
      text-align: center;

      .name {
        font-weight: 700;
      }

      p {
        margin: 0;
      }

      @media (min-width: 901px) {
        padding: 50px;
      }

      @media (min-width: 1201px) {
        padding: 50px 100px;
      }

      .curly-quote {
        color: $pale-blue;

        &--lilac {
          color: $pale-lilac;
        }
      }

      .quote {
        position: relative;
        color: $grey;
        font-family: $heading-font-face;
        font-size: 28px;
        line-height: 1.1;
        margin-bottom: 40px;
        padding-bottom: 40px;

        @media (min-width: 901px) {
          font-size: 36px;
        }

        @media (min-width: 1201px) {
          font-size: 50px;
        }

        &::after {
          content: "";
          position: absolute;
          border: solid 1px $pale-blue;
          width: 70px;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 auto;
        }

        &--lilac {
          &::after {
            border: solid 1px $pale-lilac;
          }
        }
      }

      .testimonial-icon {
        height: 50px;
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
      }

      .date {
        color: $grey;
      }
    }

    &-image {
      @media (min-width: 901px) {
        height: 460px;
      }

      @media (min-width: 1201px) {
        height: 646px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

    }

    &-icon {
      position: absolute;
      top: -25px;
      width: 100%;
      margin: 0 auto;
      left: 0;
    }
  }
}

.banner-left-overlay {
  position: relative;
  overflow: hidden;

  @media (min-width: 901px) {
    max-height: 780px;
  }

  &::before {
    @media (min-width: 901px) {
      content: '';
      background: $desktop-blue-gradient;
      height: 100%;
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }

  &__information {
    background: $dark-blue;
    padding: 20px 20px 80px !important;
    color: $white;

    @media (min-width: 901px) {
      padding: 20px 20px 60px !important;
    }

    @media (min-width: 901px) {
      padding: 0 20px !important;
      position: absolute;
      right: 0;
      left: 0;
      top: 50%;
      z-index: 2;
      transform: translateY(-50%);
      background: transparent;
    }
  }

  .content--left {
    h2 {
      margin-top: 0;
      padding-bottom: 3rem;
      max-width: 100%;
      color: $white;

      @media (min-width: 426px) {
        max-width: 70%;
      }

      @media (min-width: 769px) {
        max-width: 50%;
      }

      @media (min-width: 901px) {
        max-width: 100%;
      }

      &::after {
        margin: 0;
      }
    }

    .information {
      @media (min-width: 901px) {
        @include grid-column(6);
        margin-left: 0;
      }
    }

    p {
      margin-bottom: 40px;
    }

    a {
      color: $white;
    }

    .button {
      a {
        color: $dark-blue;
      }
    }

    .review-solicitors {
      height: 35px;
      margin-bottom: 20px;
    }

    .reviews {
      margin-bottom: 40px;
      max-width: 415px;
      width: 100%;
    }
  }

  &__background /*picture*/
  {
    position: relative;
    z-index: -1;
    overflow: hidden;
    height: 280px;
    display: block;

    &::before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      bottom: -100px;
      right: 0;
      z-index: 1;
      background: $mobile-blue-gradient;

      @media (min-width: 901px) {
        content: unset;
      }
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      object-position: top;
    }

    $gradient-push-width: 320px;

    @media (min-width: 901px) {
      left: $gradient-push-width;
      right: 0;
      max-width: calc(100vw - $gradient-push-width);
      height: 780px;
      max-height: 780px;
    }

  }
}

.testimonial-highlight {
  background: $light-grey;
  text-align: center;
  padding: 100px 0;
  color: $grey;

  @media (max-width: 900px) {
    padding: 60px 0;
  }

  &--dark-bg {
    background: $blue;
  }

  h3 {
    position: relative;
    margin-top: 20px;
    font-size: 40px;
    padding-bottom: 40px;
    color: $grey;

    @media (min-width: 901px) {
      font-size: 50px;
      line-height: 1.1;
    }

    &::after {
      content: "";
      position: absolute;
      border: solid 1px $pale-blue;
      width: 70px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
    }

    &.quote--lilac {
      &::after {
        border: solid 1px $pale-lilac;
      }
    }
  }

  p {
    margin: 0;
  }

  .name {
    color: $blue;
    font-weight: 700;
  }

  .curly-quote {
    color: $pale-lilac;
  }
}

.content-services {
  background: $light-grey;
  padding-top: 40px;

  @media (min-width: 901px) {
    padding-top: 60px;
  }

  .secondary-container {
    text-align: center;
    margin-bottom: 40px;

    h2 {
      margin: 0 auto 35px auto;
      padding: 0 25px 30px;

      @media (min-width: 901px) {
        padding: 0 0 30px;
      }

      &::after {
        margin: 0 auto;
      }
    }

    p {
      max-width: 910px;
      margin: 0 auto;
    }
  }

  .container {
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .service {
      background: $white;
      position: relative;

      &:first-child {
        margin-bottom: 80px;

        @media (min-width: 901px) {
          margin-bottom: 0;
        }
      }


      @media (min-width: 901px) {
        width: calc(50% - 20px);
        margin: 0 10px;
      }

      &-inner {
        padding: 40px 20px;


        @media (min-width: 1201px) {
          padding: 40px;
        }

        .service-area {
          text-transform: uppercase;
          letter-spacing: 2px;
          position: absolute;
          padding: 6px 20px;
          font-weight: 600;
          font-size: 14px;
          top: -16px;
          cursor: text;

          &:before {
            display: none;
          }

          &:hover {
            color: $dark-blue;
          }
        }

        ul {
          overflow: hidden;
          padding-bottom: 60px;
          border-bottom: solid 1px $light-grey;
          margin-bottom: 25px;

          li {
            font-size: 19px;
            margin-bottom: 12px;
            padding-left: 5px;
            font-weight: 700;

            @media (min-width: 901px) {
              @include grid-column(6);
              margin-left: 0;
            }
          }

          &.lilac-border {
            li {
              border-left: solid 3px $pale-lilac;
            }
          }

          &.orange-border {
            li {
              border-left: solid 3px $pale-orange;
            }
          }
        }
      }

      &-title {
        font-size: 28px;
        font-weight: 800;
        margin-bottom: 20px;
        color: $dark-blue;
      }
    }
  }
}

.latest-blog,
.blog-insights,
.template-blog {
  position: relative;

  &::before {
    content: '';
    background: $light-grey;
    position: absolute;
    top: 0;
    width: 100%;
    height: 427px;
    z-index: -1;

    @media (min-width: 901px) {
      height: 468px;
    }
  }

  h2 {
    text-align: center;

    &::after {
      margin: 0 auto;
    }

    &.title--lilac {
      &::after {
        border: solid 1px $pale-lilac;
      }
    }

    &.blog-insights__alternate-title {
      text-align: left;

      &::after {
        margin: 0;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: 901px) {
      justify-content: flex-start;
    }
  }

  &__card {
    background: $light-grey;
    margin-bottom: 40px;
    position: relative;
    transition: all 0.5s ease-out;

    &:hover {
      transform: scale(0.96);

      &:after {
        opacity: 0;
      }
    }

    &:not(:first-child) { // Delete this block once the slider has been incorporated
      display: none;

      @media (min-width: 769px) {
        display: block;
      }
    }

    @media (min-width: 769px) {
      width: calc(50% - 40px);
      margin: 0 20px 20px;
    }

    @media (min-width: 901px) {
      width: calc(33.33% - 40px);
      margin: 0 20px 20px;
    }

    &:after {
      transition: all 0.5s ease-out;
      @media (min-width: 901px) {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -20px;
        border-right: solid 1px $lighter-grey;
      }
    }

    &:nth-child(3n) {
      &::after {
        content: unset;
      }
    }
  }

  &__image {
    height: 300px;
    overflow: hidden;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .date {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 80%;
      padding: 15px;
      background: rgba(199, 232, 249, 0.8);

      &--lilac {
        background: rgba(223, 204, 239, 0.8);
      }

      &--orange {
        background: rgba(247, 196, 156, 0.8);
      }
    }
  }

  &__details {
    padding: 30px 30px 30px 30px;
    color: $blue;
    position: relative;
  }

  &__cover {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__list {
    overflow: hidden;

    @media (min-width: 901px) {
      margin: 0 -20px 60px;
    }
  }

  &__title {
    font-size: 28px;
    line-height: 1.2;
    font-weight: 800;
    padding-bottom: 20px;
    color: $dark-blue;
    margin-bottom: 0;

    a {
      text-decoration: none;
    }
  }

  .button {
    margin: 0 auto;
  }

  .categories {
    padding-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: left;
    gap: 10px 5px;

    .category {
      @extend .button;
      margin: 0 !important;
      font-size: 12px !important;
      padding: 6px 20px 5px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 600;
      text-decoration: none;

      &:before {
        content: unset;
        display: none !important;
      }

      &:hover {
        background: $pale-red;
      }

      &--lilac {
        background: $pale-lilac !important;
      }

      &--orange {
        background: $pale-orange !important;
      }

      &--blue {
        background: $pale-blue !important;
      }

      &--red {
        background: $pale-red !important;
      }
    }
  }

  &--dark-bg {
    &::before {
      content: '';
      background: $blue;
    }

    h2 {
      color: $white;
    }
  }
}

.blog-insights,
.latest-blog {
  &__card {
    &:last-of-type {
      &::after {
        content: unset;
      }
    }
  }

  &--dark-bg {
    .blog-insights__card,
    .latest-blog__card {
      &::after {
        border-right: solid 1px $light-grey;
      }
    }
  }
}

.content-left {
  position: relative;
  background: $blue;
  color: $white;
  padding: 40px 0;

  a {
    color: $white;
  }

  @media (min-width: 901px) {
    padding: 0 0 70px;
  }

  &::before {
    @media (min-width: 901px) {
      content: '';
      background: $blue;
      position: absolute;
      top: -75px;
      width: 55%;
      z-index: 2;
      height: 330px;
      left: 0;
      right: 0;
    }
  }

  &__item {
    position: relative;
    z-index: 10;

    @media (min-width: 901px) {
      @include grid-column(6);
    }

    h2 {
      color: $white;
      font-size: 2em;
      margin-bottom: 0;
      padding-bottom: 10px;
    }

    h2:after {
      content: '';
      display: none
    }
  }
}

.services-details {
  position: relative;
  background: $blue;
  color: $white;

  .main-links {
    @media (min-width: 901px) {
      @include grid-column(8);
      margin-left: 0;
    }

    ul {
      li {
        margin-bottom: 20px;


        @media (min-width: 901px) {
          margin-right: 40px;
        }

        .button {
          font-size: 20px;
          line-height: 1.2;
          font-weight: 800;
          padding: 10px 10px 10px 20px;
          color: $pale-lilac;
          background: $dark-blue;
          justify-content: space-between;
          text-decoration: none;

          &:hover {
            background: darken($dark-blue, 5%);
          }

          &:before {
            display: none;
          }

          @media (min-width: 901px) {
            padding: 12px 12px 12px 35px;
            font-size: 28px;
          }

          .arrow-right {
            background: $pale-lilac;
            height: 12px;
            padding: 8px 10px;

            @media (min-width: 901px) {
              padding: 13px 16px;
              height: 14px;
            }
          }
        }
      }
    }
  }

  .quick-links {
    margin-top: 30px;

    @media (min-width: 901px) {
      @include grid-column(4);
      margin-top: 0;
    }

    .title--lilac {
      margin-right: 170px;
      color: $pale-lilac;
      font-weight: 800;
      font-size: 24px;
    }

    ul {
      li {
        margin-bottom: 20px;

        @media (min-width: 901px) {
          margin-bottom: 25px;
        }

        a {
          color: $grey;
          font-weight: bold;
          padding-left: 10px;
          border-left: solid 3px $pale-lilac;
          text-decoration: none;
        }
      }
    }
  }

  h2 {
    color: $white !important;
    padding-bottom: 20px;
    line-height: 1em;

    @media (min-width: 901px) {
      max-width: 600px;
      margin-left: 0;
    }

    a {
      color: mix($grey, $light-grey);
    }

    &::after {
      border: solid 1px $pale-lilac;
      margin: 0;
    }
  }
}

.standard-content {
  background: $blue;
  color: $white;

  &, &--wide-text {
    &, h1, h2, h3, h4, h5 {
      color: $white;
    }

    h2:after {
      right: auto !important;
    }
  }

  @media (min-width: 901px) {
    padding: 80px 0 40px;
  }

  a {
    color: $pale-blue;
    text-decoration: none;
  }

  p {
    padding: 40px 0;
    line-height: 1.4;

    @media (min-width: 1201px) {
      @include grid-column(6);
      margin-left: 0;
      margin-right: 20px;
      font-size: 1em;
      line-height: 1.5em;
    }
  }

  &__image-float {
    margin: 0 -20px;

    @media (min-width: 1201px) {
      height: 450px;
      width: 650px;
      position: absolute;
      right: 0;
      top: 360px;
      z-index: 1;
      margin: 0;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &--with-image {
    padding-top: 0;
    min-height: 300px;
    width: 100%;
    overflow-x: hidden;

    @media (max-width: 1200px) {
      .secondary-container {
        padding-top: 0 !important;
      }
    }
  }

  ul, li {
    list-style-type: square !important; /* Default bullet style: disc, circle, square, none */
  }

  ul {
    margin: 0 0 40px 0;
    padding-left: 40px; /* Left padding for indentation */
    line-height: 1.5em;

    li {
      margin: 0 0 20px 0;

      ul {
        margin: 20px 0 40px 0;
      }
    }
  }

  .secondary-container {
    width: 100%;
    padding-top: 40px;

    .standard-content__left-text {
      width: 65%;

      @media (max-width: 1660px) {
        width: 55%;
      }

      @media (max-width: 1450px) {
        width: 42%;
      }

      @media (max-width: 1200px) {
        width: calc(100% - 40px);
        padding-top: 40px;
      }
    }

    p {
      width: 100%;
      padding: 0;
      margin: 0 0 20px 0;
    }
  }

  + .standard-content {
    padding-top: 0;
    margin-top: -170px;

    @media (max-width: 1200px) {
      margin-top: -20px;
      .secondary-container {
        width: calc(100% - 40px);
      }
    }

    @media (max-width: 900px) {
      margin-top: 0;
      padding-top: 20px;
    }
  }
}

.content__no-image-layout {
  background: $light-grey;
  padding-bottom: 100px;

  .secondary-container {
    background: $light-grey;
    position: relative;
    margin-top: -120px;
    max-width: 995px;
    overflow: unset;
    z-index: 2;
    padding: 10px 40px 0 40px;
    min-height: 150px;

    .main-content {
      padding-top: 20px;
      padding-bottom: 80px;
      color: $dark-blue;

      @media (min-width: 901px) {
        padding: 80px 80px 20px;
      }

      .highlighted-content {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          margin-bottom: 0;
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (min-width: 901px) {
        border-top: solid 1px $lighter-grey;
        padding: 20px 0 0;
        margin: 0 80px;
      }

      .previous,
      .next {
        width: 100%;
        padding: 20px 0;

        @media (min-width: 901px) {
          width: 50%;
          padding: 0;
        }

        .label {
          text-transform: uppercase;
          margin-bottom: 0;
          letter-spacing: 2px;
          font-weight: 700;
          font-size: 12px;
        }

        .title {
          color: $grey;
          font-weight: 700;
        }
      }

      .next {
        border-top: solid 1px $grey;

        @media (min-width: 901px) {
          border-top: unset;
          text-align: right;
        }
      }
    }

    .highlight {
      font-size: 20px;
      line-height: 1.5;
      font-weight: 600;
      margin-bottom: 10px;

      @media (min-width: 901px) {
        font-size: 24px;
      }
    }

    .letter-highlight {
      font-size: 72px;
      line-height: 1.1;
      font-weight: 800;
    }

    .quote-highlight {
      font-family: $heading-font-face;
      font-size: 30px;
      line-height: 1.1;
      text-align: center;
      position: relative;
      padding: 20px 0;
      margin: 40px 0;

      @media (min-width: 769px) {
        font-size: 40px;
      }

      @media (min-width: 901px) {
        font-size: 50px;
        margin: 40px -80px;
      }

      @media (min-width: 1201px) {
        margin: 40px -150px;
      }

      &::before {
        content: '';
        position: absolute;
        border: solid 1px $pale-blue;
        width: 70px;
        left: 0;
        right: 0;
        top: 0;
        margin: 0 auto;
      }

      &::after {
        content: '';
        position: absolute;
        border: solid 1px $pale-blue;
        width: 70px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
      }
    }

    .pale-blue {
      color: $pale-blue;
    }
  }
}

// Contact Form 
.contact-information {
  .message {
    @media (min-width: 901px) {
      @include grid-column(4);
    }
  }

  .contact-form {
    @media (min-width: 901px) {
      @include grid-column(8);
    }


    br {
      display: none;
    }

    &__left {


      @media (min-width: 901px) {
        @include grid-column(4);
      }

      input {
        width: calc(100% - 40px);
      }
    }

    &__right {
      @media (min-width: 901px) {
        @include grid-column(8);
      }

      textarea {
        width: calc(100% - 40px);
        height: 235px;
        resize: none;
      }

      .accept-terms {
        margin: -20px 0 10px -15px;
        display: flex;
        align-items: center;
        align-items: baseline;

        ul, li {
          font-size: 0;
        }

        span, label, .policy-accept {
          font-size: 14px;
          color: $grey;
          text-transform: none;
          letter-spacing: 0;
          font-weight: 400 !important;
        }
      }

      .button {
        float: right;
        padding: 10px 50px 10px 35px;
        margin-top: 0;
        font-weight: 700;
        font-family: $paragraph-font-face;
        background-image: url('../images/button-arrowhead-circle.svg');
        background-position: 95% center;
        background-repeat: no-repeat;
        background-size: 28px;

        &:hover {
          background-color: darken($pale-red, 7%);;
          background-size: 26px;
          background-position: 94% center;
        }

      }

      .radio-button {
        margin: 0;
      }

      .policy-accept {
        margin-left: 10px;
      }
    }

    label {
      display: block;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-left: 6px;
      font-weight: 700 !important;
      margin-bottom: 5px;

      @media (min-width: 901px) {
        font-weight: normal;
      }
    }

    .wpcf7-form-control-wrap {
      position: relative;
      display: block;
      margin-bottom: 15px;

      .wpcf7-not-valid-tip {
        position: absolute;
        top: -30px;
        right: 5px;
        font-size: 0.75em;
        color: darken($pale-red, 5%);
      }
    }

    .wpcf7-response-output {
      background: $light-grey;
      border-radius: 50px;
      border: 0;
      padding: 10px;
      text-align: center;
      clear: both;
    }

    form.failed .wpcf7-response-output {
      background: $pale-red;
    }

    .wpcf7 form.invalid .wpcf7-response-output {
      background: $pale-orange;
    }

    .wpcf7 form.sent .wpcf7-response-output {
      background: palegreen;
      color: $dark-blue;
    }

    input[type=text],
    input[type=email],
    input[type=tel],
    textarea {
      font-size: 16px;
      border: none;
      margin-bottom: 20px;
      background: $light-grey;
      padding: 10px 20px;
      border-radius: 20px;
      color: $blue;
      font-family: $paragraph-font-face;

      &.wpcf7-not-valid {
        background-image: url('../images/footer-invalid.svg');
        background-position: 99% 6px;
        background-repeat: no-repeat;
        background-size: 28px;
      }

      &[aria-invalid=false]:not(:placeholder-shown) {
        background-image: url('../images/footer-validation.svg');
        background-position: 99% 6px;
        background-repeat: no-repeat;
        background-size: 28px;
      }

      @media (min-width: 901px) {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }
  }

  .message {
    margin-bottom: 30px;

    span {
      font-weight: 700 !important;

      @media (min-width: 901px) {
        font-weight: normal;
      }
    }
  }
}


.form-contact {

  p {

    @media screen and (min-width: 500px) {
      select {
        min-width:460px;
      }
    }

    @media screen and (max-width: 500px) {
      input[type=text],
      input[type=email],
      input[type=tel],
      textarea {
        width: calc(100% - 40px);
      }

      select {
        width: 100%;
      }

      .wpcf7-radio {
        display: flex;
        flex-direction: column;
      }
    }

    br {
      display: none;
    }

    .radio-button {
      margin: 0;
    }

    .policy-accept {
      margin-left: 10px;
    }

    label {
      display: block;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-left: 6px;
      font-weight: 700 !important;
      //margin-bottom: 5px;

      @media screen and (min-width: 900px) {
        font-weight: normal;
      }
    }

    .wpcf7-submit {
      float: right;
      padding: 10px 50px 10px 35px;
      margin-top: 0;
      font-weight: 700;
      font-family: $paragraph-font-face;
      background-image: url('../images/button-arrowhead-circle.svg');
      background-position: 95% center;
      background-repeat: no-repeat;
      background-size: 28px;

      &:hover {
        background-color: darken($pale-red, 7%);;
        background-size: 26px;
        background-position: 94% center;
      }
    }

    textarea {
      width: calc(100% - 40px);
      height: 235px;
      resize: none;
    }



    input[type=text],
    input[type=email],
    input[type=tel],
    select,
    textarea {
      font-size: 16px;
      border: none;
      margin-bottom: 20px;
      background: $white;
      padding: 10px 20px;
      border-radius: 20px;
      color: $blue;
      font-family: $paragraph-font-face;

      &.wpcf7-not-valid {
        background-image: url('../images/footer-invalid.svg');
        background-position: 99% 6px;
        background-repeat: no-repeat;
        background-size: 28px;
      }

      &[aria-invalid=false]:not(:placeholder-shown) {
        background-image: url('../images/footer-validation.svg');
        background-position: 99% 6px;
        background-repeat: no-repeat;
        background-size: 28px;
      }

      @media screen and (min-width: 900px) {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }
  }



  .form-split {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1080px) {
      flex-direction: row;

      p {
        width: 50%;

      }
    }

  }

}