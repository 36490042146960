.template-team {
  &__hero {
    height: 780px !important;

    @media (min-width: 901px) {
      height: 920px !important;
    }

    .hero {
      h1 {
        @media (min-width: 901px) {
          font-size: 100px !important;
        }
      }

      .page-title {
        position: relative;
        margin-left: 0 !important;
        top: 38% !important;
        transform: translateY(-38%) !important;
        padding-bottom: 0;

        @media (min-width: 901px) {
          width: 55% !important;
        }

        h1 {
          margin-top: 0;
        }
      }

      &-banner__header-title {
        height: 780px !important;

        @media (min-width: 901px) {
          height: 920px !important;
        }
      }
    }

    .fixed-review-solicitors {
      top: 650px;
    }
  }

  &__banner-left-overlay {
    max-height: 940px !important;
  }
}

.team {
  &-content {
    position: relative;

    &__category {
      width: 100%;
      @extend .team-content__list;
    }

    .container {
      position: relative;
      overflow: unset;
      z-index: 2;

      @media (min-width: 901px) {
        padding: 75px 20px;
      }
    }

    &__desktop {
      display: none;

      @media (min-width: 901px) {
        display: block;
        margin-top: -315px;
      }
    }

    &__background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 309px;
      z-index: 5;
      background: $dark-blue;
    }

    &__mobile {
      display: block;

      @media (min-width: 901px) {
        display: none;
      }
    }

    &__list {
      display: flex;
      position: relative;
      z-index: 10;
      flex-wrap: wrap;
      @media (min-width: 901px) {
        margin: 0 -20px;
      }
    }

    &__group {
      margin-bottom: 40px;
      width: 100%;

      .title {
        font-size: 28px;
        margin-bottom: 30px;
      }

      &-list {
        .carousel-placeholder, .slick-dots {
          margin-top: 30px;
          @extend .carousel-placeholder--grey;
        }
      }
    }

    &__card {
      position: relative;

      @media (min-width: 901px) {
        width: calc(50% - 40px);
        margin: 0 20px 140px;
      }

      @media (min-width: 901px) {
        width: calc(33.33% - 40px);
        margin: 0 20px 140px;
      }

      @media (min-width: 1201px) {
        // @include grid-column(4);
        width: calc(25% - 40px);
        margin: 0 20px 140px;
      }

      &::after {
        /*Vertical separator line*/
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -20px;

        @media (min-width: 901px) {
          /*Don't show on mobile*/
          border-left: solid 1px rgba(139, 149, 165, 0.2);
        }
      }

      &:nth-child(4n + 1) {
        &::after {
          content: unset;
        }
      }

      &--w-title {
        @media (min-width: 901px) {
          margin-top: -80px;
        }
      }
    }

    &__details {
      background: $white;
      padding: 40px 20px 30px 20px;
      height: 100%;

      @media (min-width: 901px) {
        padding: 20px 30px;
      }

      .name {
        font-size: 28px;
        font-weight: 800;
        color: $dark-blue;
        line-height: 1em;
        margin-bottom: 10px;
      }

      .role {
        line-height: 1.25em;
      }

      .area-specialising {
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        .specialising {
          @extend .button;
          cursor: unset !important;
          display: inline-block !important;
          font-size: 0.6em !important;
          padding: 3px 15px !important;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 600;
          margin: 0;
          background-color: $pale-blue;

          &:before {
            display: none;
          }

          &:hover {
            background: $pale-red;
          }

          &--charity {
            background-color: $pale-red !important;
          }

          &--commercial, &--commercial-law, &--commercial-litigation, &--commercial-litigation {
            background-color: $pale-lilac !important;
          }

          &--contentious-probate, &--court-of-protection, &--employment, &--family-law, &--medical-negligence, &--personal-injury, &--personal-injury, &--power-of-attorney, &--probate,
          &--property-law,
          &--residential-property,
          &--transfers-of-equity,
          &--trusts,
          &--wills {
            background-color: $pale-orange !important;
          }


        }
      }
    }

    &__image {
      position: relative;
      height: 240px;
      min-height: 240px;
      overflow: hidden;
      width: 100%;

      .team-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .button-expand {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 15px;
        height: 16px;
        width: 16px;
        padding: 7px;
        border-radius: 100%;
        background: $pale-blue;
      }
    }

    &__card {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:after {
        transition: all 0.5s ease-out;
      }

      .button-expand {
        transition: all 0.5s ease-out;
      }

      &:hover {
        transform: scale(0.96);

        .button-expand {
          transform: scale(1.2);
        }

        &:after {
          opacity: 0;
        }
      }

      .title {
        font-size: 28px;
        font-weight: 800;
        margin-bottom: 38px;
      }
    }

    &__popup {
      display: none;

      &.opened {
        display: block;
        background: rgba($dark-blue, 0.97);
        padding-bottom: 200px;
        overflow-y: auto;
        position: fixed;
        padding-top: 140px;
        color: $white;
        z-index: 400;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        height: calc(100vh - 342px);

        @media (max-width: 900px) {
          padding-top: 110px;
        }

        .button-close {
          text-align: center;

          @media (min-width: 901px) {
            text-align: right;
            float: right;
          }

          img {
            height: 14px;
            padding: 10px;
            border-radius: 100%;
            background: darken($dark-blue, 5%);
            cursor: pointer;

            @media (min-width: 901px) {
              height: 20px;
              padding: 18px;
            }
          }
        }

        .team-content__information {
          display: flex;
          flex-wrap: wrap;

          @media (min-width: 901px) {
            display: block;
          }
        }

        .team-profile {
          margin-bottom: 40px;
          width: 100%;
        }

        .individual {
          &-title {
            .name {
              color: $white;
              font-size: 50px;
              line-height: 1.2;
              margin: 10px 0;

              @media (min-width: 901px) {
                font-size: 100px;
              }
            }
          }

          &-role {
            font-size: 24px;
            font-weight: 800;
            color: $pale-blue;
            margin-bottom: 40px;

            @media (min-width: 901px) {
              font-size: 30px;
            }
          }

          &-details {
            order: 2;

            @media (min-width: 901px) {
              @include grid-column(8);
              margin-left: 0;
            }

            .highlight {
              font-size: 24px;
              line-height: 1.4;
              font-weight: 600;
              margin-bottom: 15px;

              @media (min-width: 769px) {
                font-size: 24px;
              }
            }

            p,
            .points {
              margin-bottom: 10px;

              @media (min-width: 769px) {
                padding-right: 60px;
              }
            }
          }

          &-about {
            margin-top: 60px;
            font-size: 30px;
            font-weight: 600;
            color: $pale-blue;
          }
        }

        .team-profile {
          @media (min-width: 901px) {
            @include grid-column(4);
            margin-left: 30px;
          }

          a {
            color: $white;
          }

          &__image {
            position: relative;
            overflow: hidden;
            height: 250px;

            .team-image {
              height: 240px;
              width: 100%;
              object-fit: cover;
              object-position: center;
              margin: 0;
              display: inline-block;
            }
          }

          &__content {
            text-align: center;
            background: $white;
            color: $dark-blue;
            padding: 20px;
            margin-top: -10px;

            .review-solicitors__img {
              padding: 5px;
              margin-top: 20px;
              background: $green;
              border-bottom-right-radius: 6px;
              border-bottom-left-radius: 6px;

              img {
                width: 150px;
              }
            }

            .find-out {
              margin-top: 20px;

              a {
                color: $green;
                font-size: 16px;
                border: solid 1px;
                padding: 6px 20px;
                margin: 0;
                border-radius: 20px;
              }
            }
          }

          &__name {
            color: $green;
            font-weight: 600;
          }

          &__role {
            font-weight: 600;
            font-size: 16px;
            color: $grey;
          }

          &__contact {
            margin-top: 40px;

            .team-profile__title {
              font-size: 24px;
              font-weight: 800;
              color: $pale-blue;
            }

            .area {
              @extend .button;
              display: inline-block !important;
              font-size: 14px !important;
              padding: 5px 20px !important;
              text-transform: uppercase;
              letter-spacing: 2px;
              margin: 0 4px 12px 0;
              font-weight: 700;
              color: $blue;

              &--business {
                background: $pale-lilac !important;
              }

              &--personal {
                background: $pale-orange !important;
              }
            }

            ul {
              margin-top: 0;
              margin-bottom: 30px;
            }
          }
        }

        ul {
          li {
            list-style: square;
            margin-left: 20px;
            margin-bottom: 10px;
            text-align: left;
            line-height: 1.8;
          }
        }
      }
    }

    &__list-items {
      background: $light-grey;
    }
  }

  &-member-popup__list {
    display: none;

    &.opened {
      display: block;
    }
  }
}